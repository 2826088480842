<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Surat Menyurat</b></div>
    </b-alert>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/good-conduct-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Kelakuan Baik</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/health-insurance-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Jamkes</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/cover-letter')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Pengantar KTP</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/unmarried-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Belum Kawin</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/poverty-certificate/list')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Kurang Mampu</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/jkbm-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan JKBM</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/death-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Kematian</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/birth-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Kelahiran</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/bussiness-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Usaha</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/resident-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Penduduk</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/transfer-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Pindah</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/bussiness-place-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Keterangan Tempat Usaha</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12" @click="$router.push('/general-certificate')">
        <div class="card my-card-letter">
          <div class="card-body my-card-letter-body">
            <div class="d-flex justify-content-between align-items-center">
              <span
                class="mt-2 my-card-letter-text"
                style="font-size: 18px; color: #48aa7b; font-weight: 600"
                >Surat Umum</span
              >
              <b-button class="mr-2 icon-my-card-letter" variant="transparent">
                <img src="/media/anjungan/icon/email.png" height="65" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row d-none">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body my-card-letter-body">
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/good-conduct-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Kelakuan Baik</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/health-insurance-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Jamkes</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/cover-letter')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Pengantar KTP</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/unmarried-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Belum Kawin</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/poverty-certificate/list')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Kurang Mampu</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/jkbm-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan JKBM</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/death-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Kematian</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/birth-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Kelahiran</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/bussiness-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Usaha</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/resident-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Penduduk</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/transfer-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Pindah</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div
                  class="d-flex flex-column align-items-center"
                  @click="$router.push('/bussiness-place-certificate')"
                >
                  <b-button
                    class="mr-2 btn-circle btn-primary"
                    variant="primary"
                  >
                    <img src="/img/icons/file1.svg" height="30" />
                  </b-button>
                  <span class="mt-2">Surat Keterangan Tempat Usaha</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/users/Table.vue";

export default {
  name: "master-users",
  components: {
    Table,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        role_id: "",
      },
      // Pagination
      perPage: 5,
      currentPage: 1,
      totalRows: 60,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIK",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "gender",
          label: "Kelamin",
          sortable: true,
        },
        {
          key: "birt_date",
          label: "Tempat & Tanggal Lahir",
          sortable: true,
        },
        {
          key: "family_number",
          label: "Nomor KK",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [
        {
          number: 1,
          registration_number: "1234567891",
          name: "I MADE SUGINTA NUGRAHA",
          gender: "Laki-laki",
          birt_date: "Denpasar, 01-01-2001",
          family_number: "987654321",
          address:
            "Jl. JATAYU GG II NO. 7, Dusun/Link. Anggabaya, Kecamatan Denpasar Timur, Kota Denpasar, Provinsi Bali",
          citizenship: "WNI",
          religion: "Hindu",
          blood_type: "A",
          last_education: "SMA SEDERAJAT",
          father_name: "I WAYAN KARISUMADANA",
          mother_name: "NI KETUT SUSILAWATI",
        },
        {
          number: 2,
          registration_number: "1234567892",
          name: "I GEDE BHAKTI",
          gender: "Laki-laki",
          birt_date: "Denpasar, 01-12-2002",
          family_number: "987654322",
          address:
            "Jl. JATAYU GG II NO. 7, Dusun/Link. Anggabaya, Kecamatan Denpasar Timur, Kota Denpasar, Provinsi Bali",
          citizenship: "WNI",
          religion: "Hindu",
          blood_type: "A",
          last_education: "SMK SEDERAJAT",
          father_name: "I WAYAN GANGGA",
          mother_name: "NI KETUT JELANTIK",
        },
        {
          number: 3,
          registration_number: "1234567893",
          name: "MAYSHA JUAN",
          gender: "Perempuan",
          birt_date: "Medan, 25-01-1998",
          family_number: "987654323",
          address:
            "Jl. JATAYU GG II NO. 7, Dusun/Link. Anggabaya, Kecamatan Denpasar Timur, Kota Denpasar, Provinsi Bali",
          citizenship: "WNI",
          religion: "KATHOLIK",
          blood_type: "A",
          last_education: "SARJANA KOMUNIKASI",
          father_name: "JAN PIETER NASADIT",
          mother_name: "REGINA",
        },
        {
          number: 4,
          registration_number: "1244567894",
          name: "CARISSA LAMIA",
          gender: "Perempuan",
          birt_date: "Medan, 30-08-1995",
          family_number: "987654424",
          address:
            "Jl. JATAYU GG II NO. 7, Dusun/Link. Anggabaya, Kecamatan Denpasar Timur, Kota Denpasar, Provinsi Bali",
          citizenship: "WNI",
          religion: "PROTESTAN",
          blood_type: "A",
          last_education: "SMA SEDERAJAT",
          father_name: "I WAYAN KARISUMADANA",
          mother_name: "NI KETUT SUSILAWATI",
        },
        {
          number: 5,
          registration_number: "1254567895",
          name: "ARKHAN FIKRI",
          gender: "Laki-laki",
          birt_date: "Malang, 25-01-2005",
          family_number: "987654525",
          address:
            "Jl. JATAYU GG II NO. 7, Dusun/Link. Anggabaya, Kecamatan Denpasar Timur, Kota Denpasar, Provinsi Bali",
          citizenship: "WNI",
          religion: "Hindu",
          blood_type: "A",
          last_education: "SMA SEDERAJAT",
          father_name: "I WAYAN KARISUMADANA",
          mother_name: "NI KETUT SUSILAWATI",
        },
        {
          number: 6,
          registration_number: "1264567896",
          name: "STELLA MARSHA",
          gender: "Perempuan",
          birt_date: "Malang, 26-01-2006",
          family_number: "987664626",
          address:
            "Jl. JATAYU GG II NO. 7, Dusun/Link. Anggabaya, Kecamatan Denpasar Timur, Kota Denpasar, Provinsi Bali",
          citizenship: "WNI",
          religion: "Hindu",
          blood_type: "A",
          last_education: "SMA SEDERAJAT",
          father_name: "I WAYAN KARISUMADANA",
          mother_name: "NI KETUT SUSILAWATI",
        },
      ],
      detail: {},
      roles: [],
      role: "",
      // access
      btnAccess: true,
    };
  },
  methods: {
    showModal(data) {
      this.$bvModal.show("detail-modal");
      this.detail = data;
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&role_id=${this.filter.role_id}`;
      let response = await module.paginate(
        "users",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getRoles() {
      let response = await module.paginate("roles/select/user");
      this.roles = response.data;
    },

    filterByName() {
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("users/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Surat Menyurat", route: "/letters" },
      { title: "Buat Surat" },
    ]);
  },
};
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}

.btn-circle {
  width: 50px;
  height: 50px;
  border-radius: 35px;
  padding: 0;
}

.btn-circle-sm {
  width: 18px;
  height: 18px;
  border-radius: 30px;
  padding: 0;
}

.btn-circle i {
  font-size: 22px;
}

.my-card-letter {
  /* background-image: linear-gradient(to top, #38c762, #088346); */
  background: #ffffff;
  /* border-radius: 20px; */
  cursor: pointer;
}

.my-card-letter:hover {
  box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  -webkit-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  -moz-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
}

.icon-my-card-letter {
  width: 72px;
  height: 69px;
  border-radius: 46px;
  padding: 0;
  /* background: #ffffff; */
  background: none;
  /* border-color: #48aa7b; */
  border-color: transparent;
}

.my-card-icon{
  height: 40px;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .my-card-letter-iframe {
    /* background-image: linear-gradient(to top, #38c762, #088346); */
    /* border-color: transparent; */
    border-color: green;
    background-image: linear-gradient(to top, #c8c753, #4eaa79);
    /* background: #ffffff; */
    border-radius: 5px;
    cursor: pointer;
    /* height: 54px; */
    height: auto;
  }

  .my-card-letter-iframe:hover {
    box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
    -webkit-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
    -moz-box-shadow: 11px 11px 37px -8px rgba(16, 148, 69, 0.75);
  }

  .icon-my-card-letter-iframe {
    /* width: 72px; */
    /* height: 69px; */
    border-radius: 46px;
    padding: 0;
    /* background: #ffffff; */
    background: none;
    /* border-color: #48aa7b; */
    border-color: transparent;
  }

  .my-card-letter-text{
    font-size: 18px !important;
  }

  .my-card-icon{
    height: 30px;
  }

  .my-card-letter-body{
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>